import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


/**
 * This directive was created to solve a multiple clicks issue
 * on a button creating many equal items on database
 * 
 * Use the clicked event bidding in place of commom click event
 * 
 * 
 * ~~~html
 * <!-- do -->
 * <button (singleClick)="save()">Save</button>
 * 
 * <!-- don't -->
 * <button (click)="save()">Save</button>
 * 
 * <!-- don't -->
 * <form (ngSubmit)="save()">
 *     <button type="submit">Save</button>
 * </form>
 * ~~~
 */
@Directive({
  selector: 'button[singleClick],a[singleClick]'
})
export class SingleClickDirective implements OnDestroy{

  @Input() debounceTime = 500;
  @Output() singleClick = new EventEmitter<any>();
  private _buttonClicked = new Subject<any>();
  private _subscription: Subscription;

  constructor() {
    this._subscription = this._buttonClicked
      .pipe(debounceTime(this.debounceTime))
      .subscribe((params) => this.singleClick.emit(params));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this._buttonClicked.next(event);
  }

}
