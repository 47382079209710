import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { CepPipe } from '../pipes/cep.pipe';

@Directive({
  selector: '[cep]'
})
export class CepDirective implements AfterViewInit{

  constructor(
    private hostElement: ElementRef,
    private cepPipe: CepPipe
  ) { 
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onInput();
    }, 10);
  }

  @HostListener('input', [])
  onInput() {
    const value = this.hostElement.nativeElement.value as string;
    this.hostElement.nativeElement.value = this.cepPipe.transform(value);
  }

}
