import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegerDirective } from './integer.directive';
import { MoneyDirective } from './money.directive';
import { EditableDirective } from './editable.directive';
import { DisableEnterDirective } from './disable-enter.directive';
import { UppercaseDirective } from './uppercase.directive';
import { CepDirective } from './cep.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { PreventDefaultDirective } from './prevent-default.directive';
import { CpfDirective } from './cpf.directive';
import { CnpjDirective } from './cnpj.directive';
import { PhoneDirective } from './phone.directive';
import { SingleClickDirective } from './single-click.directive';
import { DecimalFormatterDirective } from './decimal-formatter.directive';

const directives = [
  IntegerDirective,
  MoneyDirective,
  EditableDirective,
  DisableEnterDirective,
  UppercaseDirective,
  CepDirective,
  AutoFocusDirective,
  PreventDefaultDirective,
  CpfDirective,
  CnpjDirective,
  PhoneDirective,
  SingleClickDirective,
  DecimalFormatterDirective
]

@NgModule({
  imports: [CommonModule],
  declarations: [directives],
  exports: [directives],
  providers: [directives]
})
export class DirectivesModule { }
