import { Directive, ElementRef, HostListener } from '@angular/core';
import { PhonePipe } from '../pipes/phone.pipe';

@Directive({
  selector: '[phone]'
})
export class PhoneDirective {

  constructor(
    private _hostElement: ElementRef<HTMLInputElement>,
    private _phone: PhonePipe
  ) { }

  
  @HostListener('input')
  _onInput() {
    const masked = this._phone.transform(this._hostElement.nativeElement.value)
    this._hostElement.nativeElement.value = masked;
  }

}
