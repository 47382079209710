import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { Utilities } from 'src/app/class/utilities';

@Directive({
  selector: 'input[decimalFormatterDirective]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: DecimalFormatterDirective
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalFormatterDirective),
      multi: true,
    }
  ]
})
export class DecimalFormatterDirective implements ControlValueAccessor, OnInit {

  @Input() noClass: boolean = false;
  @Input() decimalPoints: number = 2
  @Input() thousandSeparator: string = '.';
  @Input() limit: number;
  @Input() prefix: string;
  @Input() suffix: string;
  
  private _value: number | null;
  lastInputValue: string;
  commanOrDotTriggerd: boolean;

  constructor(private el: ElementRef<HTMLInputElement>) { }

  get value(): number | null {
    return this._value;
  }

  @Input('value')
  set value(value: number | null) {
    this.writeValue(value);
  }

  writeValue(value: any) {
    if (typeof value === 'string' && value !== "") {
      value = Utilities.stringToNumber(value)
    }
    this._value = value;
    this.formatValue(this._value);
  }
  
  _onChange(value: any): void {
    this.value = value;
  }
  
  _onTouched(): void { }
  
  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnInit(): void {
    if (!this.noClass) {
      this.el.nativeElement.classList.add('decimal-input');
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if(event.key == "Enter") {
      this.el.nativeElement.blur();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    if((this.decimalPoints < 1 || this.commanOrDotTriggerd) && (event.data == "," || event.data == ".")) {
      this.el.nativeElement.value = this.lastInputValue;
    }

    if(event.data == "," || event.data == ".") {
      this.commanOrDotTriggerd = true;
      this.el.nativeElement.value = this.el.nativeElement.value.replace(".",",") 
    }

    if(this.commanOrDotTriggerd && !this.el.nativeElement.value.match(/[,\.]/g)) {
      this.commanOrDotTriggerd = false;
    }

    this.handleTyping(this.el.nativeElement.value, { selectionStart: this.el.nativeElement.selectionStart, selectionEnd: this.el.nativeElement.selectionEnd }, event.data);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    const input = (event.target as any) as HTMLInputElement;
    if(input.readOnly) {
      return;
    }
    this.commanOrDotTriggerd = false;
    this.removePrefixSuffix();
    this.removeDot();
    this.el.nativeElement.setSelectionRange(0, input.value?.length ?? 3);
  }

  private formatValue(value: number) {
    if (Number.isFinite(value)) {
      if(this.limit && value > this.limit) {
        value = this.limit;
      }

      let maskedValue = new DecimalPipe('pt-BR')
        .transform(value, `1.${this.decimalPoints}-${this.decimalPoints}`)
        .replace(/\./g, this.thousandSeparator);
        
        let maskedTxt = "";
        
        if (this.prefix) {
          maskedTxt += `${this.prefix}`;
        }
        
        maskedTxt += maskedValue;
        
        if (this.suffix) {
          maskedTxt += `${this.suffix}`;
        }

        this.el.nativeElement.value = maskedTxt;
    } else {
      this.el.nativeElement.value = '';
    }
  }

  handleTyping(inputValue: string, cursor: { selectionStart: number, selectionEnd: number }, lastKeyTrigged: string) {
    const { selectionStart, selectionEnd } = cursor;
    const checkDecimalPointsregex = new RegExp(`,\\d{${this.decimalPoints}}\\d+`, "g");
    const afterCommanBiggerDecimalPoints = inputValue.match(checkDecimalPointsregex)?.[0];

    if(afterCommanBiggerDecimalPoints) {
      const numbersAfterCommanLenght = afterCommanBiggerDecimalPoints.length;
      const manipulateDecimalPointsRegex = new RegExp(`,\\d{${numbersAfterCommanLenght - (this.decimalPoints + 1)}}`, "g");

      const numbersToChangeToBeforeComman = afterCommanBiggerDecimalPoints.match(manipulateDecimalPointsRegex)?.[0];
      inputValue = inputValue.replace(numbersToChangeToBeforeComman,`${numbersToChangeToBeforeComman.replaceAll(/[,\.]/g,"")},`);

      this.el.nativeElement.value = inputValue;
      this.el.nativeElement.selectionStart = selectionStart;
      this.el.nativeElement.selectionEnd = selectionEnd;
    } 
    if(this.limit) {
      this.lastInputValue = this.checkLimit(inputValue);

      if(this.lastInputValue[this.lastInputValue.length - 1] === "," && lastKeyTrigged === "0") {
        this.lastInputValue += "0"
      }

      this.el.nativeElement.value = this.lastInputValue;
    } else {
      this.lastInputValue = inputValue;
    }
  }

  checkLimit(inputValue: string): string {
    let numInputValue = this.formatlastInputValue(inputValue).toString();
    if(!numInputValue.includes(".") && this.commanOrDotTriggerd) {
      return numInputValue + ","
    }
    return numInputValue.toString().replace(".",",");
  }

  @HostListener('blur')
  _onBlur() {
    if(!this.lastInputValue) {
      if(this.lastInputValue === "") {
        this.formatValue(0);
        this._onChange(0);
        return;
      }
      this.formatValue(this.value);
      return;
    }
    
    this.value = this.formatlastInputValue(this.lastInputValue);

    this.formatValue(this.value);
    this._onChange(this.value);
    this._onTouched(); // update form validation
  }

  formatlastInputValue(value: string): number {
    if(value === ",") {
      value = "0";
    }
    
    const numericResult = +(value?.replace(",", ".") ?? "000");
    if(this.limit !== undefined){
      return Math.min(numericResult, this.limit);
    }
    return numericResult;
  }

  removePrefixSuffix() {
    const regex = new RegExp(`[${this.prefix ?? ""}${this.suffix ?? ""}]`, "g");
    this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(regex,"");
  }

  removeDot() {
    this.el.nativeElement.value = this.el.nativeElement.value.replaceAll(/\./g,"");
  }

  getDecimalPoints(returnPowFullNumber = false): any {
    if(returnPowFullNumber){
      return Math.pow(10, this.decimalPoints);
    };
    return Math.pow(10, this.decimalPoints).toString().replace("1","");
  }
}
