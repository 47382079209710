import { Directive, ElementRef, HostListener } from '@angular/core';
import { DocumentPipe } from '../pipes/document.pipe';

@Directive({
  selector: 'input[cnpj]'
})
export class CnpjDirective {

  constructor(
    private _hostElement: ElementRef<HTMLInputElement>,
    private _cnpjPipe: DocumentPipe
  ) { }

  @HostListener('input')
  _onInput() {
    const value = this._hostElement.nativeElement.value.replace(/[\.\-\/\D]/gm, '');
    if(value.length > 11){
      const masked = this._cnpjPipe.transform(value)
      this._hostElement.nativeElement.value = masked;
    } else {
      this._hostElement.nativeElement.value = value;
    }
  }


}
