import { Directive, ElementRef, HostListener } from '@angular/core';
import { Utilities } from '../../class/utilities';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {

  constructor(private hostElement: ElementRef<HTMLInputElement>) { }

  @HostListener("input")
  onInput() {
    let value = this.hostElement.nativeElement.value || '';
    value = Utilities.removeDiacritics(value.toUpperCase()).trim();
    this.hostElement.nativeElement.value = value;
  }

}
