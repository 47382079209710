import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[mkg-editable]'
})
export class EditableDirective {

  constructor(private hostElement: ElementRef<HTMLInputElement>) { }
  
  ngOnInit(): void {
    this.hostElement.nativeElement.classList.add('editable');
  }

  @HostListener('click')
  onClick(){
    this.hostElement.nativeElement.select();
  }

}
