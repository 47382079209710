import { Directive, HostListener } from '@angular/core';
const ENTER = 13;

@Directive({
  selector: '[disableEnter]'
})
export class DisableEnterDirective {

  constructor() { }

  @HostListener('keyup', ['$event']) 
  onKeyUp (event: KeyboardEvent){
    if (event.keyCode === ENTER) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) 
  onKeyDown (event: KeyboardEvent){
    if (event.keyCode === ENTER) {
      event.preventDefault();
    }
  }

}
