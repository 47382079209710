import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utilities } from '../../class/utilities';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

const CURRENCY_SYMBOL = 'R$';

@Directive({
  selector: 'input[money]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: MoneyDirective
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyDirective),
      multi: true,
    }
  ]
})
export class MoneyDirective implements ControlValueAccessor {

  private _value: number | null;
  @Input() decimalPoints: number = 2;
  @Input() noClass: boolean = false;
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() limit: number;
  @Input() thousandSeparator = '.';


  constructor(
    private elementRef: ElementRef<HTMLInputElement>
  ) { }

  ngOnInit(): void {
    if (!this.noClass) {
      this.elementRef.nativeElement.classList.add('money');
    }
  }

  get value(): number | null {
    return this._value;
  }

  @Input('value')
  set value(value: number | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: number) {
    if (Number.isFinite(value)) {
      let maskedValue = new DecimalPipe('pt-BR')
        .transform(value, `1.${this.decimalPoints}-${this.decimalPoints}`)
        .replace(/\./g, this.thousandSeparator);
      let maskedTxt = "";
      if (this.prefix) {
        maskedTxt += `${this.prefix}`;
      }
      maskedTxt += maskedValue;
      if (this.suffix) {
        maskedTxt += `${this.suffix}`;
      }
      this.elementRef.nativeElement.value = maskedTxt;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const value = (event.target as any).value;
    let unmasked = value.replace(/\D/g, '');

    if (event.inputType === "deleteContentBackward")  {
      if(this.suffix){
        const splited = unmasked.split('');
        splited.pop();
        unmasked = splited.join('');
      }
      else {
        unmasked = unmasked.slice(0);
      }
    }
    const inputedValue = Number(unmasked) / (Math.pow(10, this.decimalPoints));
    this.value = (this.limit !== undefined && inputedValue > this.limit) ? this.limit : inputedValue;
    this.formatValue(this.value);
    this._onChange(this.value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this.value);
    this._onTouched(); // update form validation
  }

  writeValue(value: any) {
    if (typeof value === 'string' && value !== "") {
      value = Utilities.stringToNumber(value)
    }
    this._value = value;
    this.formatValue(this._value);
  }

  _onChange(value: any): void {
    this.value = value;
  }

  _onTouched(): void { }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}