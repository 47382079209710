import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[integer]'
})
export class IntegerDirective {

  constructor(private hostElement: ElementRef) { }

  @HostListener('input', [])
  onInput() {
    const value = this.hostElement.nativeElement.value as string;
    if (!value) {
      return
    }
    this.hostElement.nativeElement.value = value.replace(/\D/g, "");
  }

}
