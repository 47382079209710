import { Directive, ElementRef, HostListener } from '@angular/core';
import { DocumentPipe } from '../pipes/document.pipe';

@Directive({
  selector: 'input[cpf]'
})
export class CpfDirective {

  constructor(
    private _hostElement: ElementRef<HTMLInputElement>,
    private _cpfPipe: DocumentPipe
  ) { }

  @HostListener('blur')
  _onBlur() {
    const masked = this._cpfPipe.transform(this._hostElement.nativeElement.value)
    this._hostElement.nativeElement.value = masked;
  }


}
